/**
 * Created by andrey on 12.02.2021.
 */

var QuestsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "QuestsWindow",
            title: "QuestsWindow.title",
            content: this.createContent(),
            closeButton: true,
            foreground: bundles.windows.frames.window_foreground_png
        });

        Game.currentGame.quests.on("select", this.createDetails.bind(this), this);
    },

    createContent: function () {
        var styles = cleverapps.styles.QuestsWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        var details = this.createDetails();
        details.setAnchorPoint(0.5, 0.5);
        details.setPositionRound(styles.details);
        content.addChild(details);

        var select = this.createSelect();
        select.setAnchorPoint(0.5, 0.5);
        select.setPositionRound(styles.select);
        content.addChild(select);

        return content;
    },

    createDetails: function () {
        var styles = cleverapps.styles.QuestsWindow.details;
        var details = this.details;

        if (details) {
            details.removeAllChildren();
        } else {
            details = this.details = cleverapps.UI.createScale9Sprite(bundles.questswindow.frames.details_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            details.setContentSize2(styles);
            details.setAnchorPoint(0.5, 0.5);
        }

        if (bundles.windows.frames.details_rings) {
            styles.rings.forEach(function (style) {
                var ring = new cc.Sprite(bundles.windows.frames.details_rings);
                ring.setPositionRound(style);
                details.addChild(ring);
            });
        }

        var quest = Game.currentGame.quests.getSelectedQuest();
        if (!quest) {
            return details;
        }

        var icon = new QuestIcon(quest, {
            selected: true
        });
        icon.setPositionRound(styles.icon);
        icon.setScale(styles.icon.scale);
        details.addChild(icon);
        icon.icon.replaceParentSamePlace(details);
        icon.progress.replaceParentSamePlace(details);
        icon.progress.setLocalZOrder(1);

        var title = cleverapps.UI.generateOnlyText(quest.getTitle(), cleverapps.styles.FONTS.QUEST_DETAILS_TEXT);
        title.setDimensions(styles.title.fitTo.width, 0);
        title.fitTo(undefined, styles.title.fitTo.height);
        title.setHorizontalAlignment(styles.title.align);
        title.setPositionRound(styles.title);
        details.addChild(title);

        if (!quest.unit || !quest.unit.code || !Families[quest.unit.code].oneViewForStages) {
            var button = this.button = new cleverapps.UI.Button({
                width: styles.button.width,
                height: styles.button.height,
                text: "QuestsWindow.find",
                onClicked: function () {
                    quest.find();
                    this.close();
                }.bind(this)
            });
            button.setPositionRound(styles.button);
            details.addChild(button);
        }

        var rewardsTitle = cleverapps.UI.generateOnlyText("RewardsListComponent.rewardTitle", cleverapps.styles.FONTS.QUEST_DETAILS_TEXT);
        var rewardsList = new RewardsListComponent(quest.config.prize, {
            toMainWorld: quest.config.toMainWorld,
            font: cleverapps.styles.FONTS.QUEST_DETAILS_TEXT,
            columns: 6,
            noPrefix: true,
            small: true,
            textDirection: cleverapps.UI.HORIZONTAL
        });

        var rewards = new cleverapps.Layout([rewardsTitle, rewardsList], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.rewards.margin
        });
        rewards.setPositionRound(styles.rewards);
        details.addChild(rewards);

        if (quest && (quest.type === "spawn" || quest.type === "build") && quest.unit && quest.unit.code && UnitsLibrary.ListByCode(quest.unit.code).length > 1) {
            var units = [];
            var columns = 7;

            var questUnitStage = quest.unit.stage || Families[quest.unit.code].units.length - 1;
            var tail = 5;
            if (quest.campaign) {
                for (var stage = 1; stage < Families[quest.unit.code].units.length; stage++) {
                    units.push({
                        icon: Campaign.getCampaignQuestIcon({
                            code: quest.unit.code,
                            stage: stage
                        }),
                        goal: stage === quest.unit.stage
                    });
                }
            } else if (Families[quest.unit.code].oneViewForStages) {
                units.push({
                    code: quest.unit.code,
                    stage: quest.unit.stage,
                    goal: true
                });
            } else {
                UnitsLibrary.ListByCode(quest.unit.code).forEach(function (unit) {
                    if (unit.stage === questUnitStage) {
                        unit.goal = true;
                    } else if (unit.stage > questUnitStage) {
                        if (tail === 0) {
                            return;
                        }
                        tail--;
                    }

                    unit.unknown = !cleverapps.unitsLibrary.isOpened(unit) && unit.stage !== quest.unit.stage;

                    if (units.length >= columns) {
                        units.shift();
                    }
                    units.push(unit);
                });
            }

            var icons = units.map(function (unit) {
                var background = new cc.Scale9Sprite(unit.goal ? bundles.questswindow.frames.item_icon_bg_goal : bundles.questswindow.frames.item_icon_bg);
                background.setContentSize2(styles.items.background);
                background.setAnchorPoint(0.5, 0.5);

                var icon = unit.icon ? unit.icon : UnitView.getUnitImage(unit, { alignAnchorX: true, preferStatic: true });
                cleverapps.UI.fitToBox(icon, styles.items.icon);
                background.addChild(icon);
                background.icon = icon;
                var dy = unit.unknown ? styles.items.unknownIconDy : 0;
                icon.setPositionRound(background.width / 2, background.height / 2 + dy);

                if (!unit.goal) {
                    icon.setCascadeOpacityEnabled(true);
                    icon.setCascadeColorEnabled(true);
                    icon.setOpacity(120);
                    icon.setColor(cleverapps.styles.COLORS.UNITICON_COLOR);
                }

                return background;
            });

            var items = new cleverapps.GridLayout(icons, {
                columns: columns,
                padding: styles.items.padding,
                margin: styles.items.margin,
                separator: styles.items.separator
            });

            icons.forEach(function (icon) {
                icon.icon.replaceParentSamePlace(items);
            });

            details.addChild(items);
            items.setPositionRound(styles.items);
        } else if (button) {
            button.y -= styles.button.noItemsDelta;
            rewards.y -= styles.rewards.noItemsDelta;
            title.y -= styles.title.noItemsDelta;
        }

        return details;
    },

    createSelect: function () {
        var styles = cleverapps.styles.QuestsWindow.select;

        var icons = this.icons = Game.currentGame.quests.quests.map(function (quest) {
            var questIcon = new QuestIcon(quest, {
                images: QuestIcon.Images.small
            });

            cleverapps.UI.onClick(questIcon, function () {
                cleverapps.audio.playSound(bundles.main.urls.click_effect);
                quest.onClick();
            });

            return questIcon;
        }, this);

        var grid = this.select = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            padding: styles.padding,
            margin: styles.margin
        });

        icons.forEach(function (icon) {
            icon.makeInteractive(grid);
        });

        if (grid.width > styles.width) {
            var selectedQuest = icons.filter(function (icon) {
                return icon.quest.selected;
            })[0];

            this.select = new cleverapps.UI.ScrollView(grid, {
                childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
                direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
                scrollBarEnabled: false
            });
            this.select.setContentSize(styles.width, grid.height);
            this.select.scrollTo(selectedQuest, 0.8, {
                easing: cc.easeBackInOut()
            });
        }

        this.select.setAnchorPoint(0.5, 0.5);

        return this.select;
    },

    listBundles: function () {
        return ["questswindow"].concat(QuestsWindow.ListLazyBundles(Game.currentGame.quests.quests));
    }
});

QuestsWindow.ListLazyBundles = function (quests) {
    var bundles = [];

    cleverapps.toArray(quests || []).forEach(function (quest) {
        var res = quest.unit && quest.unit.stage !== undefined && (UnitView.UnitIconJson(quest.unit) || UnitView.UnitIconFrame(quest.unit));
        if (res && res.isLazy()) {
            bundles.push(res.getBundleName());
        }
    });

    cleverapps.lazyAssets.keepBundles(bundles);

    return bundles;
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    QUEST_DETAILS_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.QuestsWindow = {
    width: 1840,
    height: 910,

    details: {
        width: 1850,
        height: 500,

        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 0 },

        icon: {
            x: { align: "left", dx: 70 },
            y: { align: "center", dy: 10 },
            scale: 1
        },

        title: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 170 },
            align: cc.TEXT_ALIGNMENT_CENTER,
            noItemsDelta: 100,
            fitTo: {
                width: 840,
                height: 110
            }
        },

        button: {
            x: { align: "right", dx: -80 },
            y: { align: "center", dy: 80 },
            noItemsDelta: 70,
            width: 300,
            height: 110
        },

        rewards: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 60 },
            noItemsDelta: 100,
            margin: 20
        },

        items: {
            x: { align: "center", dx: 195 },
            y: { align: "center", dy: -100 },

            unknownIconDy: 4,

            background: {
                width: 160,
                height: 160
            },

            icon: {
                width: 120,
                height: 120,
                maxScale: 1.3
            },

            padding: {
                x: 20,
                y: 20
            },

            margin: {
                x: 30,
                y: 20
            },

            separator: {
                horizontal: {
                    x: { align: "center" },
                    y: { align: "center" },

                    icon: bundles.questswindow.frames.items_separator
                }
            }
        }
    },

    select: {
        width: 1980,
        margin: 60,

        x: { align: "left", dx: -70 },
        y: { align: "top", dy: -480 },

        padding: {
            x: 80,
            y: 70
        }
    }
};
