/**
 * Created by mac on 12/8/20
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    HUSTLE_DARK_BLUE_TEXT_COLOR: new cc.Color(20, 74, 131, 255),
    UPGRADABLE_LEVEL_TEXT_COLOR: new cc.Color(139, 65, 0, 255),
    PASS_REWARD_COLOR: new cc.Color(181, 81, 0, 255)
});

cleverapps.overrideFonts(cleverapps.styles.DECORATORS, {
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    INFO_TEXT: {
        name: "nostroke",
        size: 36,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    INFO_BIG_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    INFO_TIMER_TEXT: {
        name: "nostroke",
        size: 38,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },
    MENUBAR_TEXT: {
        name: "default",
        size: 48
    },
    MENUBAR_PROGRESS_TEXT: {
        name: "default",
        size: 48
    },
    MENUBAR_TIMER_TEXT: {
        size: 36,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },
    QUEST_PROGRESS_TEXT: {
        size: 38
    },
    SPEEDUP_WINDOW_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    SPEEDUP_WINDOW_HINT_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    RUINS_UNITREWARD_AMOUNT: {
        name: "default"
    },

    LEVELUPWINDOW_VALUE_TEXT: {
        size: 300,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    RUINS_CTA: {
        size: 54,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    RUINS_PLATE_TITLE: {
        size: 50,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    SELLUNITWINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    FEAST_MISSION_TIMER_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    FEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 70,
        color: new cc.Color(255, 228, 0, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    },

    FEAST_MISSION_PACK_BUTTON: {
        size: 100,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    MISSION_OFFER_REWARD: {
        name: "big_digits",
        size: 70,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UPGRADABLE_LEVEL_TEXT: {
        name: "nostroke",
        size: 38,
        color: cleverapps.styles.COLORS.UPGRADABLE_LEVEL_TEXT_COLOR
    },

    TOOL_MENU_TEXT: {
        size: 40
    },
    TOOL_MENU_ACTIVE_TEXT: {
        size: 40
    },
    TOOL_EDIT_TEXT: {
        size: 40
    },

    THIRD_ELEMENT_WINDOW_TITLE_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    THIRD_ELEMENT_WINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    BOOSTTIMEMISSIONWINDOW_DESCRIPTION_TEXT: {
        size: 50
    },

    PASS_TICKET_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    PASS_TICKET_SMALL_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    PASS_REWARD_TEXT: {
        size: 60
    },

    PASS_REWARD_COLORED_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.PASS_REWARD_COLOR
    },

    PASS_TITLE_TEXT: {
        size: 60
    },

    PASS_TIMER_TEXT: {
        size: 60
    },

    PASS_PROGRESS_POINT: {
        size: 36
    },

    PASS_PROGRESS_POINT_COMPLETED: {
        name: "nostroke",
        size: 36,
        color: cleverapps.styles.COLORS.COLOR_BROWN_2
    },

    GOLDEN_TICKET_TEXT: {
        color: cleverapps.styles.COLORS.HUSTLE_DARK_BLUE_TEXT_COLOR
    },

    MISSION_OFFER_SALE_REWARD: {
        name: "nostroke",
        size: 72,
        color: cleverapps.styles.COLORS.BROWN_BUTTON_COLOR,
        stroke: undefined,
        shadow: undefined
    }
});

cleverapps.overrideStyles(cleverapps.styles.InfoView, {
    button: {
        height: 63,
        type: {
            default: cleverapps.styles.UI.Button.Images.small_button_green,
            secondary: cleverapps.styles.UI.Button.Images.small_button_blue
        }
    },

    pointer: {
        y: -8
    },

    label: {
        padding: {
            x: 6,
            top: 15,
            bottom: 15
        }
    },

    timer: {
        padding: {
            x: 6,
            top: 15,
            bottom: 20
        }
    },

    upgradableLevel: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 54 },

        text: {
            x: { align: "center", dx: -3 },
            y: { align: "center", dy: -4 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelUpWindow["default"], {
    width: 800,
    height: 800,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 120 }
    },

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 240 }
    },

    value: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -15 }
    },

    rewards: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -110 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    Title: {
        dx: 6,
        dy: 330,

        fitTo: {
            width: 380,
            height: 100
        },

        delay: 0.7,
        duration: 0.675
    },

    animation: {
        "default": {
            idleDelay: -0.7,
            dx: 0,
            dy: -20
        },

        "starterPack0": {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        "starterPack": {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        delay: 1.5,
        duration: 0.675,
        fitTo: {
            width: 166
        },

        positions: {
            "default": {
                "hard": {
                    x: 120,
                    y: -140
                },
                "energy": {
                    x: -132,
                    y: -140
                },
                "wands": {
                    x: -310,
                    y: -70
                },
                "soft": {
                    x: -4,
                    y: -96
                },
                "worker": {
                    x: -225,
                    y: 200
                }
            },

            "starterPack": {
                "hard": {
                    x: 205,
                    y: 204
                },
                "energy": {
                    x: 294,
                    y: -74
                }
            },

            "starterPack2": {
                "hard": {
                    x: 208,
                    y: 202
                },
                "energy": {
                    x: 208,
                    y: -182
                },
                "wands": {
                    x: -235,
                    y: -182
                },
                "worker": {
                    x: -216,
                    y: 201
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarItem, {
    bg: {
        width: 270,
        minWidth: 230
    },

    icon: {
        x: 18,
        y: -2
    },

    timer: {
        icon: {
            x: { align: "left", dx: 20 },
            y: { align: "center", dy: 0 }
        },

        text: {
            x: { align: "center", dx: 14 },
            y: { align: "center", dy: -1 }
        },

        padding: {
            x: 40
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarTextView, {
    noColoredText: false
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    margin: 24,
    padding: {
        top: 260
    },

    wideModePadding: [
        {
            top: 156,
            horizontal: 20
        },
        {
            top: 206,
            horizontal: 40
        },
        {
            top: 216,
            horizontal: 50
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.PrizeView, {
    "default": {
        width: 90,
        height: 100,

        pointer: {
            dy: -1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SettingsWindow, {
    button: {
        margin: 19,

        width: 563,
        width2: 269,
        width3: 175
    }
});

cleverapps.overrideStyles(cleverapps.styles.WorkerComponentTimer, {
    button: {
        height: 63,
        y: -60
    }
});

cleverapps.overrideStyles(cleverapps.styles.PixelView, {
    collect: {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: 50 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FogBlockView, {
    opener: {
        text: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: -2 }
        },

        icon: {
            x: { align: "left", dx: -8 },
            y: { align: "center", dy: 2 }
        },

        blick: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -1 }
        }
    },

    blocker: {
        text: {
            x: { align: "center", dx: -4 },
            y: { align: "center", dy: -28 }
        },

        icon: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: -30 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UpgradableView, {
    level: {
        x: { align: "center", dx: 3 },
        y: { align: "center", dy: 26 },

        text: {
            x: { align: "center", dx: -3 },
            y: { align: "center", dy: -4 }
        }
    },

    progress: {
        x: { align: "center" },
        y: { align: "bottom", dy: 30 },

        height: 80,
        width: 280,

        stars: [
            {
                x: { align: "center", dx: -112 },
                y: { align: "center", dy: 26 }
            },
            {
                x: { align: "center", dx: -58 },
                y: { align: "center", dy: 11 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            },
            {
                x: { align: "center", dx: 58 },
                y: { align: "center", dy: 11 }
            },
            {
                x: { align: "center", dx: 112 },
                y: { align: "center", dy: 26 }
            }
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.BuyFogWindow, {
    island: {
        height: 600,

        y: { align: "center", dy: 220 },

        shine: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: -80 }
        }
    },

    goods: {
        linesMargin: 30
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorX: 0.5,
            factorY: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrystalGuideWindow, {
    firstStage: {
        unit: {
            x: 0,
            y: 0,
            scale: 1.4,
            dy: 60
        },

        components: [
            {
                x: -157,
                y: -166,
                scale: 1.2
            },
            {
                x: -4,
                y: -264,
                scale: 1.2
            },
            {
                x: 155,
                y: -166,
                scale: 1.2
            }
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.FogBalloonView, {
    height: 180,
    width: 250,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -20 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.WheelLotteryWindow, {
    height: 700,

    table: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -310 }
    },

    playButton: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -430 },
        width: 300
    },

    note: {
        width: 270,
        height: 100,
        x: { align: "center", dx: 446 },
        y: { align: "bottom", dy: 160 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.RuinsWindow, {
    plate: {
        width: 410,
        height: 460,

        titleBg: {
            width: 350,
            height: 68,

            x: { align: "center" },
            y: { align: "top", dy: 22 }
        },
        
        units: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -90 }
        },

        title: {
            width: 220,
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 24 }
        },

        button: {
            y: { align: "bottom", dy: 60 }
        }
    },

    unit: {
        amount: {
            x: { align: "left", dx: 96 },
            y: { align: "center", dy: 5 }
        }
    }

});

cleverapps.overrideStyles(cleverapps.styles.ToolbarView, {
    margin: 25,
    position: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -5 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SpecialEnergyOfferWindow, {
    animation: {
        x: { align: "center" },
        y: { align: "center", dy: 50 }
    },

    reward: {
        x: { align: "center" },
        y: { align: "center", dy: -100 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksCompleteAllView, {
    textLayout: {
        width: 740
    }
});

cleverapps.overrideStyles(cleverapps.styles.FeastMissionWindow, {
    table: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -18 },
        height: 735,
        innerPadding: 0
    },

    mainTitle: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 510 },
        width: 500
    },

    helpButton: {
        x: { align: "center", dx: -300 },
        y: { align: "center", dy: 506 }
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -520 }
    },

    finishTitle: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -520 }
    },

    contentPadding: {
        bottom: 70
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetY: 345,
        offsetX: 205,

        title: {
            x: { align: "center", dx: 238 },
            y: { align: "center", dy: -105 },
            width: 250
        }
    },
    tap: {
        x: { align: "left", dx: -245 },
        y: { align: "center", dy: 616 }
    },
    button: {
        height: 110,
        offsetX: 0,
        offsetY: 10,

        badge: {
            x: { align: "right", dx: 40 },
            y: { align: "top", dy: 45 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.LIVESFEAST], {
    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -150 }
    },

    rewards: {
        energy: {
            x: { align: "center", dx: -475 },
            y: { align: "center", dy: -134 },
            rotation: 0
        },

        worker: {
            x: { align: "center", dx: 477 },
            y: { align: "center", dy: -134 },
            rotation: 0
        }
    },

    button: {
        x: { align: "center", dx: -2 },
        y: { align: "bottom", dy: 30 }
    }
});

cleverapps.styles.UnitOnCellExpedition = cleverapps.overrideStyles(cleverapps.styles.UnitOnCell, {
    ground: {
        x: 3,
        y: -20
    }
}, true);

cleverapps.overrideStyles(cleverapps.styles.ImproverGuideWindow, {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -100 }
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -100 }
        },

        generator: {
            x: { align: "center" },
            y: { align: "center", dy: 10 },
            scale: 1.8,

            timer: {
                x: { align: "center", dx: 10 },
                y: { align: "bottom", dy: -4 },
                scale: 0.9
            }
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -100 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LandMarkDonorWindow, {
    tabs: {
        x: { align: "left", dx: -138 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ThirdElementWindow, {
    infoBlock: {
        height: 360,
        icon: {
            x: { align: "right", dx: -40 },
            y: { align: "center", dy: -10 }
        },

        timer: {
            width: 280,
            height: 66,
            x: { align: "left", dx: 60 },
            y: { align: "center", dy: 10 },
            margin: 20
        }
    },

    title: {
        width: 600,
        height: 110,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 165 }
    },

    buttons: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 10 },
        margin: 60,
        button: {
            width: 360,
            height: 104
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoostTimeMissionWindow, {
    description: {
        x: { align: "center" },
        y: { align: "center", dy: -430 }
    },

    ribbon: {
        x: { align: "center", dx: 290 },
        y: { align: "center", dy: 300 }
    },

    buyPackButton: {
        x: { align: "center", dx: 280 },
        y: { align: "center", dy: -320 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionOfferWindow[Offers.TYPES.BUILDPASS], {
    height: 640,
    width: 1400,

    badges: [
        {
            x: { align: "center", dx: 214 },
            y: { align: "top", dy: -38 },
            width: 125,
            rotation: 10
        }
    ],

    rewards: {
        font: cleverapps.styles.FONTS.MISSION_OFFER_SALE_REWARD,
        unit: {
            bppointsstar: {
                x: { align: "center", dx: -460 },
                y: { align: "center", dy: -230 },
                width: 540,
                rotation: 0
            },
            crystal: {
                x: { align: "center", dx: 460 },
                y: { align: "center", dy: -232 },
                width: 540,
                rotation: 0
            }
        }
    },

    button: {
        height: 99,
        y: { align: "bottom", dy: -100 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FeastMissionWindow, {
    batteries: [{
        x: { align: "center", dx: 470 },
        y: { align: "bottom", dy: -70 }
    }, {
        x: { align: "center", dx: -440 },
        y: { align: "bottom", dy: -70 }
    }, {
        x: { align: "center", dx: -190 },
        y: { align: "bottom", dy: -70 }
    }]
});

cleverapps.overrideStyles(cleverapps.styles.PassRulesWindow, {
    firstStage: {
        unit: {
            scale: 1
        }
    },

    secondStage: {
        light: {
            x: -5,
            y: 33
        },

        unit: {
            x: -10,
            y: 45,
            scale: 1.3
        },

        star: {
            x: -5,
            y: 150,
            scale: 1.2
        },

        finger: {
            x: 140,
            y: -110
        }
    },

    thirdStage: {
        prizes: {
            x: 5,
            y: 80,
            scale: 1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroIcon, {
    icon: {
        x: { align: "center", dx: -3 },
        y: { align: "bottom", dy: 6 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.InstantWorkerGuideWindow, {
    firstStage: {
        unit: {
            dy: 75
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack, {
    badge: {
        x: { align: "right", dx: 141 },
        y: { align: "center", dy: 11 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack[Offers.TYPES.SALEPASS], {
    badge: {
        x: { align: "right", dx: 141 },
        y: { align: "center", dy: 11 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassPackBadge, {
    text: {
        x: { align: "center", dx: -3 },
        y: { align: "center", dy: 32 },
        rotation: 11,
        width: 120
    }
});
